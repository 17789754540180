<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("USERS.ADD_USER") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <user-form
              :loading="loading"
              :userData="user"
              :formErrors="formErrors"
              @userSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultUser from "./defaultUser";
import UserForm from "./partials/UserForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    UserForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      user: cloneDeep(defaultUser),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Users" });
    },

    viewUser(user) {
      this.alertLeave = false;
      this.$router.push({ name: "View User", params: { id: user.id } });
    },

    async handleSubmit(user) {
      this.loading = true;

      const userData = cloneDeep(user);
      delete userData.id;
      if (userData.roles[0]) {
        if (!userData.roles[0].id) {
          delete userData.roles;
          userData.roles = [];
        }
      }
      if (!userData.organization.id) {
        delete userData.organization;
      }
      if (!userData.reseller.id) {
        delete userData.reseller;
      }

      try {
        await this.$store.dispatch("users/add", userData);
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ADDED"),
        });
        const user = await this.$store.getters["users/user"];
        this.viewUser(user);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const user = await this.$store.getters["users/user"];
        if (user && user.id) {
          await this.$store.dispatch("users/destroy", user.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
